<template>
  <TTView>
    <VRow>
      <VCol>
        <VCard>
          <VCardTitle>Редактирование смс конфигурации</VCardTitle>
          <VCardSubtitle><strong>ID компании:</strong> {{ companyId }}</VCardSubtitle>
          <VCardText>
            <VTextarea
              v-model="smsText"
              name="sms_config_text"
              label="Текст сообщения"
              no-resize
              filled
            />
            <VCheckbox
              v-model="enabled"
              label="Активна"
            />
          </VCardText>
          <VBtn
            depressed
            color="primary"
            class="ml-4 mb-5"
            @click="updateConfig"
          >
            Сохранить
          </VBtn>
        </VCard>
      </VCol>
    </VRow>
  </TTView>
</template>

<script>
export default {
  name: 'SmsConfigEdit',
  inject: ['Names'],
  data() {
    return {
      companyId: null,
      smsText: '',
      enabled: false,
    };
  },
  created() {
    this.companyId = this.$route.params.companyId;

    this.$di.api.Talent.smsConfigGet({ companyId: this.companyId })
      .then((response) => {
        this.smsText = response.text;
        /** Note: устанавливаем false вручную, потому что бэкенд дефолтное поле не присылает */
        this.enabled = response.enabled || false;
      })
      .catch(this.$di.notify.errorHandler);
  },
  methods: {
    updateConfig() {
      this.toggleEnable();

      const payload = {
        companyId: this.companyId,
        text: this.smsText,
      };

      this.$di.api.Talent.smsConfigUpdate(payload)
        .then(this.goToSmsConfigsList)
        .catch(this.$di.notify.errorHandler);
    },
    toggleEnable() {
      const { companyId } = this;

      this.$di.api.Talent.smsConfigToggle({ companyId }).catch(this.$di.notify.errorHandler);
    },
    goToSmsConfigsList() {
      this.$router.push({ name: this.Names.R_TALENT_SMS });
    },
  },
};
</script>
